<template>
    <div id="about_page">

        <!-- Start About -->
        <About />
        <!-- End About -->

        <!-- Start Service_About -->
        <Service_About />
        <!-- End Service_About -->

        <section data-v-731136a8="" data-v-3d996ecc="" id="vision">
            <div class="container" data-v-731136a8="">
                <div id="vision_wrapper" class="d-none d-sm-none d-md-none d-lg-block d-xl-block" data-v-731136a8="">
                    <div class="row grid gap-3" data-v-731136a8="">
                        <div class="col content_wrapper p-3" data-v-731136a8="">
                            <h3 class="fw-bold mb-4 wow animate__ animate__backInDown animate__slow           animate__animated "
                                data-wow-offset="1" data-wow-delay="0.1s" data-v-731136a8=""><img
                                    src="@/assets/img/our_vision.1423f036.svg" alt="" class="ms-3"
                                    data-v-731136a8="">رؤيتنا
                            </h3>
                            <p class="text-justify wow animate__ animate__backInUp animate__slow            animate__animated"
                                data-wow-offset="1" data-wow-delay="0.2s" data-v-731136a8="">أن نكون قادرين على تقديم
                                كافة
                                الخدمات التي تمكن الجهات التعليمية والمدربين النهوض بالطلاب نحو مستقبل قائم على النهضة
                                الصناعية الرابعة وتعزيز ثقتهم بنفسهم</p>
                        </div>
                        <div class="col content_wrapper p-3" data-v-731136a8="">
                            <h3 class="fw-bold mb-4 wow animate__ animate__backInDown animate__slow            animate__animated"
                                data-wow-offset="1" data-wow-delay="0.1s" data-v-731136a8=""><img
                                    src="@/assets/img/our_message.589fa009.svg" alt="" class="ms-3"
                                    data-v-731136a8="">رسالتنا
                            </h3>
                            <p class="text-justify wow animate__ animate__backInUp animate__slow            animate__animated"
                                data-wow-offset="1" data-wow-delay="0.2s" data-v-731136a8="">تنمية روح الإبداع للطلاب
                                ومساعتهم
                                على خلق حلول مبتكرة لمواجهة المشاكل المجتمعية</p>
                        </div>
                    </div>
                </div>
                <div id="vision_wrapper_mobile" class="d-block d-sm-block d-md-block d-lg-none d-xl-none"
                    data-v-731136a8="">
                    <div class="content_wrapper_mobile p-3 mb-3" data-v-731136a8="">
                        <h3 class="fw-bold mb-4 wow animate__animated animate__backInDown animate__slow animate__animated"
                            data-wow-offset="1" data-wow-delay="0.1s" data-v-731136a8=""><img
                                src="@/assets/img/our_vision.1423f036.svg" alt="" class="ms-3" data-v-731136a8="">رؤيتنا
                        </h3>
                        <p class="text-justify wow animate__animated animate__backInUp animate__slow animate__animated"
                            data-wow-offset="1" data-wow-delay="0.2s" data-v-731136a8="">أن نكون قادرين على تقديم كافة
                            الخدمات
                            التي تمكن الجهات التعليمية والمدربين النهوض بالطلاب نحو مستقبل قائم على النهضة الصناعية
                            الرابعة وتعزيز ثقتهم بنفسهم</p>
                    </div>
                    <div class="content_wrapper_mobile p-3" data-v-731136a8="">
                        <h3 class="fw-bold mb-4 wow animate__animated animate__backInDown animate__slow animate__animated"
                            data-wow-offset="1" data-wow-delay="0.1s" data-v-731136a8=""><img
                                src="@/assets/img/our_message.589fa009.svg" alt="" class="ms-3"
                                data-v-731136a8="">رسالتنا </h3>
                        <p class="text-justify wow animate__animated animate__backInUp animate__slow animate__animated"
                            data-wow-offset="1" data-wow-delay="0.2s" data-v-731136a8="">تنمية روح الإبداع للطلاب
                            ومساعتهم على
                            خلق حلول مبتكرة لمواجهة المشاكل المجتمعية</p>
                    </div>
                </div>
            </div>
        </section>

        <section data-v-4093d3d2="" data-v-3d996ecc="" id="know_us">
            <div class="container" data-v-4093d3d2="">
                <h3 class="text-center fw-bold text-justify mb-4 wow animate__ animate__backInDown animate__slow            animate__animated"
                    data-wow-offset="1" data-wow-delay="0.1s" data-v-4093d3d2=""><img
                        src="@/assets/img/know_us.27af53b2.svg" alt="" class="ms-3" data-v-4093d3d2="">تعرف علينا </h3>
                <div id="video_wrapper"
                    class="ratio ratio-21x9 wow animate__ animate__backInUp animate__slow            animate__animated"
                    data-wow-offset="1" data-wow-delay="0.2s" data-v-4093d3d2=""><video
                        poster="@/assets/img/Steam_Video.5e5faff2.webp" controls="" data-v-4093d3d2="">
                        <source src="@/assets/media/STEAMademy.7099205b.webm#t=1" type="video/webm" data-v-4093d3d2="">
                        <source src="@/assets/media/STEAMademy.da38eecf.mp4#t=1" type="video/mp4" data-v-4093d3d2="">
                        Your browser
                        does not support the video tag.
                    </video></div>
            </div>
        </section>

        <!-- Start Footer -->
        <Footer />
        <!-- End Footer -->

    </div>
</template>

<script>

import About from '@/components/about/About.vue';
import Service_About from '@/components/about/Service_About.vue';

export default {
    name: 'AboutView',
    components: {
        About,
        Service_About,
    },
    mounted() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    },
}

</script>
<style scoped src="@/assets/css/app.a60e7e5f.css"></style>
<style scoped lang="scss">
#about_page {

    // https://www.w3schools.com/cssref/pr_background-image.php
    /* The image used */
    background-image: url("@/assets/images/about/about_BG.jpg");

    /* Used if the image is unavailable */
    background-color: $White;
    /* Center the image */
    background-position: center;
    /* Repeat the image */
    background-repeat: repeat;
    /* Resize the background image to cover the entire container */
    background-size: cover;

}
</style>
<template>


    <section data-v-4ffecce8="" id="commercial_concession">
        <div class="container" data-v-4ffecce8="">
            <div class="row" data-v-4ffecce8="">
                <div id="text_content" class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 my-auto" data-v-4ffecce8="">
                    <h3 class="fw-bold text-justify mb-4 wow animate__ animate__backInDown animate__slow  animate__animated"
                        data-wow-offset="1" data-wow-delay="0.1s" data-v-4ffecce8=""
                        style="visibility: visible; animation-delay: 0.1s;"> امتياز STEAM التجاري</h3>
                    <p class="text-justify mb-4 wow animate__ animate__backInUp animate__slow  " data-wow-offset="1"
                        data-wow-delay="0.2s" data-v-4ffecce8="">يوفر لك منظومة متكاملة لتحقيق أفضل عائد
                        على استثماراتك بداية من التجهيزات والمحتوى العلمي وتأهيل فريقك وحتى وسائل التواصل مع الطلاب
                        والتسويق كل ما عليك هو أن تؤمن بقدرتك على تغيير المستقبل وتحقيق أهداف الثورة الصناعية
                        الرابعة.</p>
                </div>
                <div id="img_content"
                    class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 wow animate__ animate__fadeIn animate__slow   animate__animated"
                    data-wow-offset="1" data-wow-delay="0.3s" data-v-4ffecce8="">
                    <div id="img_wrapper" data-v-4ffecce8=""><img src="@/assets/img/Kid_pic.81e6150f.webp" alt=""
                            data-v-4ffecce8=""></div>
                </div>
            </div>
        </div>
    </section>
    <section data-v-de40eb92="" id="advantages">
        <div data-v-de40eb92="" class="container">
            <h3 class="fw-bold mb-4 text-justify wow animate__ animate__backInDown animate__slow                                                                                                                                                                            "
                data-wow-offset="1" data-wow-delay="0.1s" data-v-de40eb92=""> إليك مميزات الامتياز التجاري STEAMdemy
            </h3>
            <div id="content_wrapper"
                class="d-none d-sm-none d-md-none d-lg-block d-xl-block wow animate__ animate__backInUp animate__slow   animate__animated                                                                                                                                                                         "
                data-wow-offset="1" data-wow-delay="0.2s" data-v-de40eb92="">
                <div class="row gy-4" data-v-de40eb92="">
                    <div class="col-lg-6 col-xl-4 d-flex align-items-stretch" data-v-de40eb92="">
                        <div class="card" data-v-de40eb92=""><img src="@/assets/img/advantage_1.c16c529c.webp"
                                class="card-img-top"
                                alt="intercultural-kids-working-pairs-while-girls-controlling-virtual-presentation"
                                data-v-de40eb92="">
                            <div class="card-body" data-v-de40eb92="">
                                <h5 class="card-title fw-bold" data-v-de40eb92="">التجهيزات والأثاث</h5>
                                <p class="card-text" data-v-de40eb92="">نضمن لك أن جهتك التعليمية ستكون&nbsp; مناسبة
                                    لتنفيذ نهج STEAM التعليمي ومشجعة على التعلم عن طريق توفير STEAMademy لتصميم
                                    داخلي فريد للجهات التعليمية. نجهز المعامل بكل المعدات ونوفر كل التجهيزات
                                    المناسبة مثل وحدات التخزين وطاولات المنافسة وأجهزة الحاسب الآلي والشاشات
                                    التفاعلية.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-4 d-flex align-items-stretch" data-v-de40eb92="">
                        <div class="card" data-v-de40eb92=""><img src="@/assets/img/advantage_2.cb029976.webp"
                                class="card-img-top" alt="metal-3d-printer-with-abstract-lines" data-v-de40eb92="">
                            <div class="card-body" data-v-de40eb92="">
                                <h5 class="card-title fw-bold" data-v-de40eb92="">الأدوات والأجهزة</h5>
                                <p class="card-text" data-v-de40eb92="">نوفر الأدوات والأجهزة اللازمة للتعلم وتطبيق
                                    نتائج التعلم من خلال مشاريع واقعية، تشمل هذه الأدوات مجموعات الروبوتات والدوائر
                                    الكهربائية وإلكترونيات تطبيق إنترنت الأشياء والطابعات ثلاثية الأبعاد.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-4 d-flex align-items-stretch" data-v-de40eb92="">
                        <div class="card" data-v-de40eb92=""><img src="@/assets/img/advantage_3.bc3d1642.webp"
                                class="card-img-top" alt="" data-v-de40eb92="">
                            <div class="card-body" data-v-de40eb92="">
                                <h5 class="card-title fw-bold" data-v-de40eb92="">الأدلة وجودة التشغيل</h5>
                                <p class="card-text" data-v-de40eb92="">نوفر لك جميع أدلة التشغيل لتقدم نهج STEAM
                                    التعليمي بأفضل كفاءة تشغيل، تشمل أدلة الإجراءات التشغيلية متابعة سير عملية
                                    التعلم ونظام تقييم الطلاب والمدربين والموظفين بالإضافة لأدلة الصيانة الدورية
                                    وتحضير المعامل والبحث والتطوير.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-4 d-flex align-items-stretch" data-v-de40eb92="">
                        <div class="card" data-v-de40eb92=""><img src="@/assets/img/advantage_4.87acb73a.webp"
                                class="card-img-top" alt="father-sons-making-robot" data-v-de40eb92="">
                            <div class="card-body" data-v-de40eb92="">
                                <h5 class="card-title fw-bold" data-v-de40eb92="">البرمجيات التعليمية</h5>
                                <p class="card-text" data-v-de40eb92="">نقدم كل البرمجيات التي تحتاجها في عملية
                                    التعليم أو إنشاء المشاريع أو حتى متابعة الطلاب والأهل: برامج التصميم الهندسي
                                    مثل: AutoCAD, Tinkercad, SolidWorks اللغات البرمجية. C# , C++, Python تطبيقات
                                    متابعة الأهل. منصات الابتكار ودراسات الجدوى.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-4 d-flex align-items-stretch" data-v-de40eb92="">
                        <div class="card" data-v-de40eb92=""><img src="@/assets/img/advantage_5.a1aacf58.webp"
                                class="card-img-top" alt="tech-devices-icons-connected-digital-planet-earth"
                                data-v-de40eb92="">
                            <div class="card-body" data-v-de40eb92="">
                                <h5 class="card-title fw-bold" data-v-de40eb92="">اختبارات STEAM الدولية</h5>
                                <p class="card-text" data-v-de40eb92="">نقدم اختبارات STEAM الدولية لتتمكن من قياس
                                    جودة العملية التعليمية سواءً للطلاب أو المدربين أو الجهات التعليمية.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-4 d-flex align-items-stretch" data-v-de40eb92="">
                        <div class="card" data-v-de40eb92=""><img src="@/assets/img/advantage_6.832529b0.webp"
                                class="card-img-top" alt="disabled-man-shows-financial-diagrams-office"
                                data-v-de40eb92="">
                            <div class="card-body" data-v-de40eb92="">
                                <h5 class="card-title fw-bold" data-v-de40eb92="">تأهيل المدربين</h5>
                                <p class="card-text" data-v-de40eb92="">نقدم برامج تدريب متخصصة لمعلمين نهج STEAM
                                    التعليمي سواءً كانوا تابعين للجهات التعليمية أو مدربين منفردين ، تشمل عملية
                                    التأهيل تدريبًا على ما يلى: المفاهيم الأساسية في STEAM استراتيجيات التعليم النشط
                                    والتفاعلي التكنولوجيا في التعليم القيادة وإدارة المدارس STEAM</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div data-v-de40eb92="" id="content_wrapper_mobile"
                class="d-block d-sm-block d-md-block d-lg-none d-xl-none wow animate__animated animate__backInUp animate__slow "
                data-wow-offset="1" data-wow-delay="0.2s">
                <div data-v-de40eb92="" id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-inner" data-v-de40eb92="">
                        <div class="carousel-item active" data-bs-interval="5000" data-v-de40eb92="">
                            <div class="card" data-v-de40eb92=""><img src="@/assets/img/advantage_1.c16c529c.webp"
                                    class="card-img-top"
                                    alt="intercultural-kids-working-pairs-while-girls-controlling-virtual-presentation"
                                    data-v-de40eb92="">
                                <div class="card-body" data-v-de40eb92="">
                                    <h5 class="card-title fw-bold" data-v-de40eb92="">التجهيزات والأثاث</h5>
                                    <p class="card-text" data-v-de40eb92="">نضمن لك أن جهتك التعليمية ستكون&nbsp;
                                        مناسبة لتنفيذ نهج STEAM التعليمي ومشجعة على التعلم عن طريق توفير STEAMademy
                                        لتصميم داخلي فريد للجهات التعليمية. نجهز المعامل بكل المعدات ونوفر كل
                                        التجهيزات المناسبة مثل وحدات التخزين وطاولات المنافسة وأجهزة الحاسب الآلي
                                        والشاشات التفاعلية.</p>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item" data-bs-interval="5000" data-v-de40eb92="">
                            <div class="card" data-v-de40eb92=""><img src="@/assets/img/advantage_2.cb029976.webp"
                                    class="card-img-top" alt="metal-3d-printer-with-abstract-lines" data-v-de40eb92="">
                                <div class="card-body" data-v-de40eb92="">
                                    <h5 class="card-title fw-bold" data-v-de40eb92="">الأدوات والأجهزة</h5>
                                    <p class="card-text" data-v-de40eb92="">نوفر الأدوات والأجهزة اللازمة للتعلم
                                        وتطبيق نتائج التعلم من خلال مشاريع واقعية، تشمل هذه الأدوات مجموعات
                                        الروبوتات والدوائر الكهربائية وإلكترونيات تطبيق إنترنت الأشياء والطابعات
                                        ثلاثية الأبعاد.</p>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item" data-bs-interval="5000" data-v-de40eb92="">
                            <div class="card" data-v-de40eb92=""><img src="@/assets/img/advantage_3.bc3d1642.webp"
                                    class="card-img-top" alt="" data-v-de40eb92="">
                                <div class="card-body" data-v-de40eb92="">
                                    <h5 class="card-title fw-bold" data-v-de40eb92="">الأدلة وجودة التشغيل</h5>
                                    <p class="card-text" data-v-de40eb92="">نوفر لك جميع أدلة التشغيل لتقدم نهج
                                        STEAM التعليمي بأفضل كفاءة تشغيل، تشمل أدلة الإجراءات التشغيلية متابعة سير
                                        عملية التعلم ونظام تقييم الطلاب والمدربين والموظفين بالإضافة لأدلة الصيانة
                                        الدورية وتحضير المعامل والبحث والتطوير.</p>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item" data-bs-interval="5000" data-v-de40eb92="">
                            <div class="card" data-v-de40eb92=""><img src="@/assets/img/advantage_4.87acb73a.webp"
                                    class="card-img-top" alt="father-sons-making-robot" data-v-de40eb92="">
                                <div class="card-body" data-v-de40eb92="">
                                    <h5 class="card-title fw-bold" data-v-de40eb92="">البرمجيات التعليمية</h5>
                                    <p class="card-text" data-v-de40eb92="">نقدم كل البرمجيات التي تحتاجها في عملية
                                        التعليم أو إنشاء المشاريع أو حتى متابعة الطلاب والأهل: برامج التصميم الهندسي
                                        مثل: AutoCAD, Tinkercad, SolidWorks اللغات البرمجية. C# , C++, Python
                                        تطبيقات متابعة الأهل. منصات الابتكار ودراسات الجدوى.</p>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item" data-bs-interval="5000" data-v-de40eb92="">
                            <div class="card" data-v-de40eb92=""><img src="@/assets/img/advantage_5.a1aacf58.webp"
                                    class="card-img-top" alt="tech-devices-icons-connected-digital-planet-earth"
                                    data-v-de40eb92="">
                                <div class="card-body" data-v-de40eb92="">
                                    <h5 class="card-title fw-bold" data-v-de40eb92="">اختبارات STEAM الدولية</h5>
                                    <p class="card-text" data-v-de40eb92="">نقدم اختبارات STEAM الدولية لتتمكن من
                                        قياس جودة العملية التعليمية سواءً للطلاب أو المدربين أو الجهات التعليمية.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item" data-bs-interval="5000" data-v-de40eb92="">
                            <div class="card" data-v-de40eb92=""><img src="@/assets/img/advantage_6.832529b0.webp"
                                    class="card-img-top" alt="disabled-man-shows-financial-diagrams-office"
                                    data-v-de40eb92="">
                                <div class="card-body" data-v-de40eb92="">
                                    <h5 class="card-title fw-bold" data-v-de40eb92="">تأهيل المدربين</h5>
                                    <p class="card-text" data-v-de40eb92="">نقدم برامج تدريب متخصصة لمعلمين نهج
                                        STEAM التعليمي سواءً كانوا تابعين للجهات التعليمية أو مدربين منفردين ، تشمل
                                        عملية التأهيل تدريبًا على ما يلى: المفاهيم الأساسية في STEAM استراتيجيات
                                        التعليم النشط والتفاعلي التكنولوجيا في التعليم القيادة وإدارة المدارس STEAM
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div data-v-de40eb92="" class="d-flex justify-content-center"><button data-v-de40eb92=""
                            class="carousel-control-next" type="button" data-bs-target="#carouselExampleSlidesOnly"
                            data-bs-slide="next"><svg data-v-de40eb92="" class="svg-inline--fa fa-chevron-right"
                                aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right"
                                role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                <path class="" fill="currentColor"
                                    d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z">
                                </path>
                            </svg></button><button data-v-de40eb92="" class="carousel-control-prev" type="button"
                            data-bs-target="#carouselExampleSlidesOnly" data-bs-slide="prev"><svg data-v-de40eb92=""
                                class="svg-inline--fa fa-chevron-left" aria-hidden="true" focusable="false"
                                data-prefix="fas" data-icon="chevron-left" role="img" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 320 512">
                                <path class="" fill="currentColor"
                                    d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z">
                                </path>
                            </svg></button></div>
                </div>
            </div>
        </div>
    </section>
    <section data-v-a33ad11c="" id="content">
        <div class="container" data-v-a33ad11c="">
            <div id="content_wrapper" class="p-3" data-v-a33ad11c="">
                <div class="row" data-v-a33ad11c="">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 wow animate__ animate__backInRight animate__slow       animate__animated                                                                                                                                                                      "
                        data-wow-offset="1" data-wow-delay="0.1s" data-v-a33ad11c="">
                        <h3 class="fw-bold mb-3" data-v-a33ad11c="">المحتوى الرقمي</h3>
                        <p class="mb-3 mb-sm-3 mb-md-3 mb-lg-0" data-v-a33ad11c="">نقدم محتوى تعليمي رقمي تفاعلي
                            متكامل لإعداد المتدربين لوظائف النهضة الصناعية الرابعة، هذا المحتوى قائم على مبادئ نهج
                            STEAM التعليمي وهي: <br data-v-a33ad11c=""> العلوم <br data-v-a33ad11c=""> التكنولوجيا
                            <br data-v-a33ad11c=""> الهندسة <br data-v-a33ad11c=""> الفنون <br data-v-a33ad11c="">
                            الرياضيات
                        </p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 wow animate__ animate__backInRight animate__slow                animate__animated                                                                                                                                                            "
                        data-wow-offset="1" data-wow-delay="0.2s" data-v-a33ad11c="">
                        <h5 class="fw-bold mb-3" data-v-a33ad11c="">يهدف المحتوى لتوفير:</h5>
                        <ul data-v-a33ad11c="">
                            <li data-v-a33ad11c="">برامج تعليمية متكاملة.</li>
                            <li data-v-a33ad11c="">مشاريع تطبيق المفاهيم العلمية. تعليم برمجة الروبوتات.</li>
                            <li data-v-a33ad11c="">تعليم تصميم المواقع والتطبيقات.</li>
                            <li data-v-a33ad11c="">تدريب الطلاب على حل المشكلات العملية.</li>
                            <li data-v-a33ad11c="">محتوى تعليمي متعدد الوسائط. تقييم وتوجيه شامل.</li>
                        </ul>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 d-flex justify-content-center align-items-center wow animate__ animate__backInLeft animate__slow     animate__animated                                                                                                                                                                      "
                        data-wow-offset="1" data-wow-delay="0.3s" data-v-a33ad11c="">
                        <div id="img_wrapper" data-v-a33ad11c=""><img src="@/assets/img/content_img.d834bae4.webp"
                                alt="children-standing-sideways-camera-looking-charge-boards"
                                class="d-none d-sm-none d-md-block d-lg-none d-xl-block" data-v-a33ad11c=""><img
                                src="@/assets/img/content_img_mobile.d829e408.webp"
                                alt="children-standing-sideways-camera-looking-charge-boards"
                                class="d-block d-sm-block d-md-none d-lg-block d-xl-none" data-v-a33ad11c=""></div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- Start Footer -->
    <Footer />
    <!-- End Footer -->

</template>

<script>
import Header from '@/components/home/Header.vue';
export default {
    name: 'CommercialConcessionView',
    components: {
        Header,
    },
    mounted() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    },
}
</script>

<style scoped src="@/assets/css/app.a60e7e5f.css"></style>